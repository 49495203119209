<template>
    <side-panel class="group-details">
        <div class="position-sticky title p-3">
            <div class="d-flex flex-row justify-content-between mb-1 mr-1">
                <router-link class="btn btn-outline-dark border-0 rounded-lg"
                             v-bind:to="{ 'path': `/groups`, 'query': $route.query }">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
                <h3 class="flex-grow-1 font-weight-bolder text-right">
                    {{ name }}
                </h3>
            </div>
            <div class="">
                <p class="text-dark text-right mb-0">
                    Created <strong>{{ timeCreated }}</strong>
                </p>
                <p class="text-dark text-right mb-0">
                    Led by <strong>{{ leader }}</strong>
                </p>
            </div>
        </div>
        <div class="actions position-sticky d-flex px-3 py-2">
            <button class="btn btn-block btn-warning rounded-pill shadow-sm mr-1 my-0"
                    v-bind:class="{ 'btn-loading': isLoading, }"
                    v-on:click="isModalEditOpen = true">
                ✏️ Update
            </button>
            <button class="btn btn-block btn-danger rounded-pill shadow-sm ml-1 my-0"
                    v-bind:class="{ 'btn-loading': isLoading, }"
                    v-on:click="isModalDeleteConfirmOpen = true">
                🗑️ Delete
            </button>
        </div>
        <modal-group v-bind:group="group"
                     v-model="isModalEditOpen">
        </modal-group>
        <modal-delete-confirm field="name"
                              v-bind:target="group"
                              v-bind:callback="deleteGroup"
                              v-model="isModalDeleteConfirmOpen">
        </modal-delete-confirm>
    </side-panel>
</template>

<script>
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import mongoObjectIdToDate from "@/utilities/mongoObjectIdToDate";
export default {
    name: "GroupDetails",
    components: {
        SidePanel: () => import("@/components/SidePanel"),
        ModalGroup: () => import("@/components/Groups/ModalGroup"),
        ModalDeleteConfirm: () => import("@/components/modals/ModalDeleteConfirm"),
    },
    props: {
        groupId: {
            type: String,
        },
    },
    data () {
        return {
            isLoading: false,
            isModalEditOpen: false,
            isModalDeleteConfirmOpen: false,
        };
    },
    computed: {
        group () {
            return this.$store.getters["group/itemById"](this.groupId);
        },
        id () {
            return this.group?._id ?? "";
        },
        chat () {
            return this.$store.getters["whatsapp/chatById"](this.id);
        },
        avatar () {
            return ``;
        },
        name () {
            return this.group?.name ?? "";
        },
        leader () {
            if (this.group?.leaderUser) {
                const target = this.$store.getters["users/itemById"](this.group.leaderUser);
                if (target) {
                    return `${ target?.name } (+${ target.countryCode } ${ target.phone })`;
                }
            }
            return this.group?.leader ?? "";
        },
        timeCreated () {
            return this.group?.createdAt || this.id ?
                formatDistanceToNow(new Date(this.group.createdAt || mongoObjectIdToDate(this.id)), { "addSuffix": true }) :
                "NA";
        },
    },
    methods: {
        async deleteGroup () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("group/delete", this.id);
                this.$router.push("/groups");
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
